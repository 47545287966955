import type { PageComponent } from '@/core/features/a-dynamic-page/dynamic-page-pacts/dynamic-page-type';
import type { InfiniteData } from '@/core/features/react-query/react-query-service';
import type { PageComponentInterface } from '@packages/open-api';

import React from 'react';

import { getComponentId } from '@/core/features/a-component/services/component-id-service';
import {
    getComponentClientSide,
    getPaginatedComponentQueryKey,
} from '@/core/features/a-component/services/component-service';
import useDynamicPageComponentByIri from '@/core/features/a-dynamic-page/hooks/use-dynamic-page-component-by-iri';
import { useDynamicPageQueryData } from '@/core/features/a-dynamic-page/hooks/use-dynamic-page-query';
import { logger } from '@/core/features/logger/logger';
import { useInfiniteQuery } from '@/core/features/react-query/react-query-service';
import { DEFAULT_QUERY_STALE_TIME } from '@/core/features/request/request-constants';
import { getURL } from '@/core/utils/url';

export default function useComponentPagination<Component extends PageComponent>(
    initialDynamicPageComponentIri: string,
    componentTargetIri?: null | string,
    getNextTarget = defaultNextTargetGetter,
) {
    const dynamicPageComponent = useDynamicPageComponentByIri<Component>(initialDynamicPageComponentIri);

    const componentIri = componentTargetIri ?? initialDynamicPageComponentIri;

    /**
     * Effect: Update query key only when dynamic page is not loading new data
     * otherwise the new query key will have previous data as stale initial data
     */
    const [queryKey, setQueryKey] = React.useState(getPaginatedComponentQueryKey(componentIri));
    const { isFetching: isDynamicPageFetching, isLoading: isDynamicPageLoading } = useDynamicPageQueryData();

    React.useEffect(() => {
        if (isDynamicPageLoading || isDynamicPageFetching) {
            return;
        }
        setQueryKey(getPaginatedComponentQueryKey(componentIri));
    }, [componentIri, isDynamicPageFetching, isDynamicPageLoading]);

    // avoid fetching the component if it is already loaded by dynamic page data
    // e.g. when selecting filter, switching verticals etc.
    const initialPagesData: Component[] | undefined = React.useMemo(
        () =>
            dynamicPageComponent && dynamicPageComponent.loading === 'eager' && !componentTargetIri
                ? [dynamicPageComponent]
                : undefined,
        [componentTargetIri, dynamicPageComponent],
    );

    const initialData: InfiniteData<Component> | undefined = initialPagesData && {
        pageParams: [componentIri],
        pages: initialPagesData,
    };

    const query = useInfiniteQuery<unknown, Error, { pages: Component[] | undefined }>({
        getNextPageParam: (lastPage) => getNextTarget(lastPage, componentIri),
        initialData,
        initialPageParam: componentIri,
        queryFn: (context) => {
            if (typeof context.pageParam !== 'string') {
                logger.error(
                    `context.pageParam has an unexpected type ${typeof context.pageParam} in useComponentPagination for ${componentTargetIri}`,
                );
                return;
            }
            const nextComponentIri = context.pageParam;
            if (!nextComponentIri) {
                logger.error(
                    `Could not locate nextComponentIri ${nextComponentIri} in useComponentPagination for ${componentTargetIri}`,
                );
                return;
            }
            const url = getURL(nextComponentIri);
            const componentId = getComponentId(nextComponentIri);

            return getComponentClientSide(componentId, url.searchParams);
        },
        queryKey,
        staleTime: DEFAULT_QUERY_STALE_TIME,
    });

    const { data, error, fetchNextPage, hasNextPage, isError, isFetchingNextPage, isLoading } = query;

    const [pages, setPages] = React.useState<Component[]>(initialPagesData ?? []);

    React.useEffect(() => {
        if (isLoading && !error) {
            return;
        }

        setPages(data?.pages ?? []);
    }, [data, error, isLoading]);

    if (error) {
        logger.error('Error in useComponentPagination', {
            additionalData: JSON.stringify({
                componentIri,
                componentTargetIri,
                error,
                initialDynamicPageComponentIri,
            }),
        });
    }

    const lastPage = pages?.[pages.length - 1];

    // console.info('useComponentPagination', {
    //     componentIri,
    //     componentTargetIri,
    //     dynamicPageComponent,
    //     initialData,
    //     initialDynamicPageComponentIri,
    //     initialPagesData,
    //     pages: { data: data?.pages, lastPage, pages },
    //     query,
    //     queryKey,
    // });

    return {
        error,
        fetchNextPage,
        hasNextPage: hasNextPage,
        isError,
        isFetchingNextPage,
        isLoading,
        lastPage,
        pages,
    };
}
type NextTarget = PageComponentInterface<string, { loadMore?: { target: string } }, {}>;

const isNextTargetGetter = (lastPage: unknown): lastPage is NextTarget =>
    (lastPage as NextTarget)?.attributes?.loadMore !== undefined;

const defaultNextTargetGetter = (lastPage: undefined | unknown, componentIri: string): string | undefined => {
    try {
        if (!lastPage) {
            return;
        }
        if (isNextTargetGetter(lastPage)) {
            return lastPage.attributes.loadMore?.target;
        }
        return;
    } catch (error) {
        logger.error(`Error in defaultNextTargetGetter for ${componentIri}: ${error}`);
        return;
    }
};

import { bemModule } from '@/core/utils/bem-classname';

import styles from './spot-result-title.module.scss';

interface SpotResultTitleProps {
    extraPaddingRight: boolean;
    subtitle: string;
    title: string;
}

const bem = bemModule(styles);

export default function SpotResultTitle({ extraPaddingRight, subtitle, title }: SpotResultTitleProps) {
    return (
        <>
            <h2 className={`ellipsis ${bem(styles.spotResultTitle, { extraPaddingRight })}`}>{title}</h2>
            <h3
                className={`ellipsis font-size-12 margin-bottom-4 ${bem(styles.spotResultSubTitle, {
                    extraPaddingRight,
                })}`}
            >
                {subtitle}
            </h3>
        </>
    );
}

import React from 'react';

/** This hook was designed to address loading flickering issues if the API responses returned too quickly */
export default function useLoadingIndicator(
    isLoading: boolean,
    config: {
        loadingThreshold?: number;
        minIndicatorTime?: number;
    } = {},
) {
    const { loadingThreshold = 500, minIndicatorTime = 1000 } = config;
    /*  Initialized as false, as this triggers the loading indicator to appear after a brief delay if
	`isLoading === true` persists after x milliseconds. */
    const [showLoadingIndicator, setShowLoadingIndicator] = React.useState(false);
    const loadingStartTime = React.useRef<null | number>(null);
    const indicatorTimeTimeoutId = React.useRef<NodeJS.Timeout>();

    React.useEffect(() => {
        // Evaluate if loadingIndicator should be shown after loadingThreshold
        const indicatorThresholdTimeoutId = setTimeout(() => {
            if (isLoading) {
                loadingStartTime.current = new Date().getTime();
                setShowLoadingIndicator(true);
            }
        }, loadingThreshold);

        if (!isLoading && loadingStartTime.current) {
            const loadingDuration = new Date().getTime() - loadingStartTime.current;
            // If minIndicatorTime not reached, hide loadingIndicator after the remaining time has passed
            if (loadingDuration < minIndicatorTime) {
                indicatorTimeTimeoutId.current = setTimeout(() => {
                    setShowLoadingIndicator(false);
                    loadingStartTime.current = null;
                }, minIndicatorTime - loadingDuration);
            } else {
                clearTimeout(indicatorThresholdTimeoutId);
                setShowLoadingIndicator(false);
            }
        }
        return () => {
            clearTimeout(indicatorThresholdTimeoutId);
            clearTimeout(indicatorTimeTimeoutId.current);
        };
    }, [isLoading, loadingThreshold, minIndicatorTime]);

    return showLoadingIndicator;
}

import type { SpotResultHorizontalTile } from '@/features/spot-results/spot-results-v4/spot-results-v4-type';

import { Badges, Image, KeyFacts, Link, WishlistButton } from '@/core/features';
import color from '@/core/features/styles/color';
import SeasonCalendar from '@/features/season-calendar/season-calendar';
import { trackSpotResultClick } from '@/features/spot-results/spot-result-tracking';
import SpotResultHorizontalTitle from '@/features/spot-results/spot-results-v4/spot-result-title/spot-result-title';
import SpotScoreBadge from '@/features/spot-score/spot-score-badge';

type SpotResultHorizontalProps = SpotResultHorizontalTile;

export default function SpotResultHorizontal({
    badges,
    calendar,
    id,
    image,
    keyFacts,
    linkUrl,
    spotScore,
    subtitle,
    title,
    wishlistButton,
}: SpotResultHorizontalProps) {
    return (
        <div
            className={'flex-start-vertical width-100 min-width-fit-content border-radius'}
            // eslint-disable-next-line no-inline-styles/no-inline-styles
            style={{ boxShadow: `0px 1px 8px ${color('black-015')}` }}
        >
            <Link
                fullWidth
                href={linkUrl}
                linkType={'internal'}
                onClick={() => trackSpotResultClick(title, { isMap: false })}
                qaId={'qa-spot-page-link'}
            >
                <div
                    className={'grid-cols-auto-1fr border-radius overflow-hidden bg-white'}
                    // eslint-disable-next-line no-inline-styles/no-inline-styles
                    style={{ minHeight: 220 }}
                >
                    <Image
                        alt={image.imageAlt}
                        container={'horizontal'}
                        height={300}
                        objectFit={'cover'}
                        preload={true}
                        url={image.imageUrl}
                        width={120}
                    />
                    <div
                        className={'flex-column relative ellipsis padding-x-10 padding-top-15 padding-bottom-15 grow-1'}
                    >
                        <SpotResultHorizontalTitle
                            extraPaddingRight={!!wishlistButton}
                            subtitle={subtitle ?? '\u00A0'} // reserve whitespace if not available
                            title={title}
                        />
                        <SpotScoreBadge
                            marginBottom={10}
                            scoreMarginRight={'margin-right-8'}
                            spotScore={spotScore}
                        />
                        <SeasonCalendar
                            rowCount={2}
                            seasonCalendar={calendar.seasonCalendar}
                            title={calendar.title}
                            titleMarginBottom={5}
                        />
                        <div className={'margin-top-10 min-width-0'}>
                            <KeyFacts
                                fontSize={'font-size-12'}
                                gap={8}
                                iconSize={12}
                                keyFacts={keyFacts}
                                truncation={'ellipsis'}
                            />
                        </div>
                        {badges.length >= 1 && (
                            <div className={'flex-start-vertical margin-top-12 margin-bottom-15'}>
                                <Badges badges={badges} />
                            </div>
                        )}
                        {wishlistButton && (
                            <div
                                className={'flex-center absolute top-0 right-0'}
                                // eslint-disable-next-line no-inline-styles/no-inline-styles
                                style={{ margin: '-4px -3px 0 0' }}
                            >
                                <WishlistButton
                                    isInitiallySelected={wishlistButton.isSelected}
                                    type={'plain'}
                                    wishlistId={id}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </Link>
        </div>
    );
}

import type { SpotResultHorizontalAttributes } from '@/features/spot-results/spot-results-v4/spot-results-v4-type';

import React from 'react';

import { HtmlTextWrapper, IconImage, Link } from '@/core/features';
import color from '@/core/features/styles/color';
import SpotResultHorizontal from '@/features/spot-results/spot-results-v4/spot-result/spot-result-horizontal';

type SpotResultHorizontalProps = SpotResultHorizontalAttributes;

export default function SpotResultHorizontalContainer({
    link,
    subtitle,
    tiles,
    title,
    topLivePrices,
}: SpotResultHorizontalProps) {
    const [isTopLivePriceOpen, setTopLivePriceOpen] = React.useState(false);

    const singleCtaLink =
        topLivePrices?.ctaList.length === 1 && topLivePrices.ctaList[0] ? topLivePrices.ctaList[0].linkUrl : null;

    return (
        <div className={'width-100'}>
            <div className={'flex justify-space-between align-center'}>
                <div className={'font-size-16'}>
                    <div className={'bold'}>{title}</div>
                    <div className={'font-size-12'}>{subtitle}</div>
                </div>
                {link && (
                    <div className={'flex font-size-12 align-center gap-5'}>
                        <Link
                            href={link.url}
                            linkType={'internal'}
                        >
                            <span style={{ color: color('endeavour') }}>{link.text}</span>
                        </Link>
                        <IconImage
                            height={10}
                            url={link.iconUrl}
                            width={10}
                        />
                    </div>
                )}
            </div>
            <div
                className={'full-width flex gap-10 overflow-y-hidden padding-10 no-scrollbar'}
                /*  eslint-disable no-inline-styles/no-inline-styles */
                style={{
                    overflow: 'scroll',
                    scrollPaddingLeft: 10,
                    scrollSnapType: 'x mandatory',
                }}
            >
                {tiles.map((tile, index) => (
                    <div
                        key={`${tile.id}-${index}`}
                        style={{
                            flex: `0 0 ${tiles.length < 2 ? '100%' : 'calc(100% - 30px)'}`, // ensures to show 30px of next tile (gap-10 + 20px)
                            scrollSnapAlign: 'start',
                        }}
                    >
                        <SpotResultHorizontal {...tile} />
                    </div>
                ))}
            </div>
            {topLivePrices &&
                (singleCtaLink ? (
                    <Link
                        fullWidth={true}
                        href={singleCtaLink}
                        linkType={'vertical'}
                    >
                        <LinkContent
                            isTopLivePriceOpen={isTopLivePriceOpen}
                            singleCtaLink={singleCtaLink}
                            topLivePrices={topLivePrices}
                        />
                    </Link>
                ) : (
                    <div onClick={() => setTopLivePriceOpen(!isTopLivePriceOpen)}>
                        <LinkContent
                            isTopLivePriceOpen={isTopLivePriceOpen}
                            singleCtaLink={singleCtaLink}
                            topLivePrices={topLivePrices}
                        />
                    </div>
                ))}
            {isTopLivePriceOpen && topLivePrices && (
                <div className={'flex flex-column gap-5 margin-top-10'}>
                    {topLivePrices.ctaList.map((cta, index) => {
                        return (
                            <Link
                                href={cta.linkUrl}
                                key={`${cta.title}-${index}`}
                                linkType={'vertical'}
                            >
                                <div
                                    className={
                                        'flex align-center bg-white gap-10 padding-10 border border-radius-7 border-alto justify-space-between'
                                    }
                                >
                                    <div className={'flex gap-10 align-center'}>
                                        <IconImage
                                            height={14}
                                            url={cta.iconUrl}
                                            width={14}
                                        />
                                        <HtmlTextWrapper
                                            className={'font-size-14'}
                                            htmlText={`${cta.htmlTitle}`}
                                        />
                                    </div>
                                    <div className={'flex flex-column align-end'}>
                                        <div>
                                            {cta.price.prefixText && (
                                                <span className={'font-size-10'}>{cta.price.prefixText}</span>
                                            )}
                                            <HtmlTextWrapper
                                                className={'font-size-14'}
                                                htmlText={` ${cta.price.htmlText}`}
                                            />
                                        </div>
                                        {cta.price.subtitle && (
                                            <HtmlTextWrapper
                                                className={'font-size-10'}
                                                htmlText={`${cta.price.subtitle}`}
                                            />
                                        )}
                                    </div>
                                </div>
                            </Link>
                        );
                    })}
                </div>
            )}
        </div>
    );
}

const LinkContent = ({
    isTopLivePriceOpen,
    singleCtaLink,
    topLivePrices,
}: {
    isTopLivePriceOpen: boolean;
    singleCtaLink: null | string;
    topLivePrices: NonNullable<SpotResultHorizontalProps['topLivePrices']>;
}) => {
    return (
        <div className={'width-100 flex align-center justify-end gap-5'}>
            <HtmlTextWrapper
                className={'font-size-12'}
                htmlText={isTopLivePriceOpen ? 'verbergen' : topLivePrices.htmlText}
            />
            <IconImage
                height={12}
                rotate={singleCtaLink ? undefined : isTopLivePriceOpen ? -90 : 90}
                url={topLivePrices.iconUrl}
                width={12}
            />
        </div>
    );
};
